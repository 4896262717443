import TextareaAutosize from 'react-textarea-autosize';

import { NavLink } from '@remix-run/react';

import styles from './styles.module.css';

import { withStyledComponents } from './with-styled-component';

export const ErrorCount = withStyledComponents(styles.ErrorCount, 'span', {
  small: styles.small,
});
export const TabButtonsWrapper = withStyledComponents(styles.TabButtonsWrapper, 'div', {
  small: styles.small,
  marginTop: styles.marginTop,
});
export const TabButton = withStyledComponents(styles.TabButton, 'button', {
  active: styles.active,
});
export const TabButtonDelete = withStyledComponents(styles.TabButtonDelete, 'button', {
  active: styles.active,
});
export const ButtonGroup = withStyledComponents(styles.buttonGroup, 'div');
export const InlineWrapper = withStyledComponents(styles.InlineWrapper, 'div', {
  spacingTopLarge: styles.spacingTopLarge,
  spacingTop: styles.spacingTop,
  spacingBottom: styles.spacingBottom,
  wrapMobile: styles.wrapMobile,
  relative: styles.relative,
  spaceBetween: styles.spaceBetween,
  centered: styles.centered,
  end: styles.end,
  bottom: styles.bottom,
  top: styles.top,
  grow: styles.grow,
  tight: styles.tight,
  littleTight: styles.littleTight,
  mobileTight: styles.mobileTight,
  noGapMobile: styles.noGapMobile,
});
export const IconLink = withStyledComponents(styles.IconLink, 'a', {
  withBackground: styles.withBackground,
  withBorder: styles.withBorder,
  noMargin: styles.noMargin,
  light: styles.light,
  large: styles.large,
  small: styles.small,
});
export const IconNavLink = withStyledComponents(styles.IconLink, NavLink, {
  withBackground: styles.withBackground,
  withBorder: styles.withBorder,
  noMargin: styles.noMargin,
  light: styles.light,
  large: styles.large,
  small: styles.small,
});
export const IconButton = withStyledComponents(styles.IconButton, 'button', {
  withBackground: styles.withBackground,
  withBorder: styles.withBorder,
  noMargin: styles.noMargin,
  light: styles.light,
  large: styles.large,
  small: styles.small,
});
export const AccountActionsWrapper = withStyledComponents(styles.AccountActionsWrapper, 'div');
export const Fields = withStyledComponents(styles.Fields, 'div');
export const ProgressWrapper = withStyledComponents(styles.ProgressWrapper, 'div');
export const Progress = withStyledComponents(styles.Progress, 'p');
export const Duration = withStyledComponents(styles.Duration, 'p');
export const IconsWrapper = withStyledComponents(styles.IconsWrapper, 'div');
export const ActionsWrapper = withStyledComponents(styles.ActionsWrapper, 'div', { spaceBetween: styles.spaceBetween });
export const Overlay = withStyledComponents(styles.Overlay, 'div', {
  showMobile: styles.showMobile,
  absolute: styles.absolute,
  fullHeight: styles.fullHeight,
});
export const Divider = withStyledComponents(styles.Divider, 'span', {
  horizontal: styles.horizontal,
  wide: styles.wide,
  spacing: styles.spacing,
});
export const DialogContent = withStyledComponents(styles.DialogContent, 'div');
export const DialogFooter = withStyledComponents(styles.DialogFooter, 'div');
export const DialogHeader = withStyledComponents(styles.DialogHeader, 'div');
export const DialogActions = withStyledComponents(styles.DialogActions, 'div');
export const CloseButton = withStyledComponents(styles.CloseButton, 'button');
export const FieldWrapper = withStyledComponents(styles.FieldWrapper, 'div', {
  noMarginTop: styles.noMarginTop,
  noMarginBottom: styles.noMarginBottom,
  fullWidth: styles.fullWidth,
  halfWidth: styles.halfWidth,
  thirdWidth: styles.thirdWidth,
  mobileFullWidth: styles.mobileFullWidth,
  quarterWidth: styles.quarterWidth,
  eigthWidth: styles.eigthWidth,
});
export const Prefix = withStyledComponents(styles.Prefix, 'span');
export const Suffix = withStyledComponents(styles.Suffix, 'span');
export const InputWrapper = withStyledComponents(styles.InputWrapper, 'label');
export const Note = withStyledComponents(styles.Note, 'p', {
  smallMarginTop: styles.smallMarginTop,
  marginTop: styles.marginTop,
  noMargin: styles.noMargin,
  centered: styles.centered,
});
export const SpanLabel = withStyledComponents(styles.SpanLabel, 'span', {
  isInline: styles.isInline,
  isOverlayed: styles.isOverlayed,
  ellipsis: styles.ellipsis,
});
export const StyledInput = withStyledComponents(styles.StyledInput, 'input');
export const StyledTextarea = withStyledComponents(styles.StyledTextarea, TextareaAutosize, { small: styles.small });
export const CancelButton = withStyledComponents(styles.CancelButton, 'button', { large: styles.large });
export const DeleteButton = withStyledComponents(styles.DeleteButton, 'button', {
  large: styles.large,
  noMargin: styles.noMargin,
});
export const Badge = withStyledComponents(styles.Badge, 'span', {});
export const Error = withStyledComponents(styles.Error, 'span', {
  noMargin: styles.noMargin,
  tightMargin: styles.tightMargin,
});
export const BaseBtn = withStyledComponents(styles.TournamentNavBtn, 'button', {
  isAlignedRight: styles.isAlignedRight,
  tiny: styles.tiny,
  noLineHeight: styles.noLineHeight,
  small: styles.small,
  medium: styles.medium,
  xLarge: styles.xLarge,
});
export const TextBtn = withStyledComponents(styles.TextBtn, 'button', {
  isAlignedRight: styles.isAlignedRight,
  noMargin: styles.noMargin,
});
export const BaseBtnLabel = withStyledComponents(styles.TournamentNavBtn, 'label', {
  isAlignedRight: styles.isAlignedRight,
  small: styles.small,
  medium: styles.medium,
});
export const BaseBtnNav = withStyledComponents(styles.TournamentNavBtn, NavLink, {
  isAlignedRight: styles.isAlignedRight,
  small: styles.small,
  medium: styles.medium,
});
export const SecondaryBtnLabel = withStyledComponents(styles.SecondaryBtn, BaseBtnLabel);
export const SecondaryBtn = withStyledComponents(styles.SecondaryBtn, BaseBtn);
export const MainBtn = withStyledComponents(styles.MainBtn, BaseBtn, {
  fullWidth: styles.fullWidth,
  noDisabledState: styles.noDisabledState,
  smallMargin: styles.smallMargin,
});
export const TitleLabel = withStyledComponents(styles.TitleLabel, 'span');
export const NextSectionBtn = withStyledComponents(styles.NextSectionBtn, BaseBtn, {
  primary: styles.primary,
});
export const MainBtnNavLink = withStyledComponents(styles.MainBtnNavLink, NavLink, {
  small: styles.small,
  medium: styles.medium,
});
export const EmptyState = withStyledComponents(styles.EmptyState, 'div', {
  noMargin: styles.noMargin,
  smallMargin: styles.smallMargin,
  noBtnStyles: styles.noBtnStyles,
  padding: styles.padding,
  leftAligned: styles.leftAligned,
});
export const CloseBtn = withStyledComponents(styles.CloseBtn, 'button', {
  closer: styles.closer,
  relative: styles.relative,
});

export const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className={styles.ProgressBar}>
      <span className={styles.inner} style={{ right: `${100 - progress}%` }} />
    </div>
  );
};
